@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.proposalAccelerator {
    &__rightsec {
        background: $white;
        padding-left: 310px;
        width: 100%;
        padding-bottom: 150px;
        border-right: 1px solid $gray--300;
        position: relative;
    }

    &__form {
        padding: 30px;

        input {
            font-family: $sans-reg;
            border: 1px solid $gray--dark;
            border-radius: 4px;
            display: block;
            padding: 17px;
            width: 100%;

            &:focus-visible {
                // outline: 1px solid $gray--dark;
                outline: none;
            }
        }

        label {
            font-family: $sans-bold;
            font-weight: 700;

        }
    }

    &__field {
        margin-bottom: 30px;
        width: 100%;

        .txbase {
            .ui.input {
                width: 100%;

                input {
                    border: 1px solid #b7b7b7;
                    padding: 18px 10px;
                    border-radius: 4px;
                }
            }
        }
    }

    &__submitcontainer {
        margin-top: 30px;
        text-align: center;
    }

    &__results {
        padding: 30px;
    }

}