@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";


.followupquestion {

    &__wrap {
        // padding-top: 10px;
        margin: 10px;
        text-align: right;
    }

    &__title {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: $gray--700;
        margin-bottom: 10px;
        display: inline-block
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5em;
        justify-content: flex-end;
    }

    &__item {
        border: 1px solid $purple--300;
        padding: 10px 20px;
        border-radius: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $gray--700;

        &:hover {
            cursor: pointer;
        }
    }

}