// typo & color scss
@import "src/global-styles/typography";
@import "src/global-styles/colors";

.tabs {
  position: relative;

  &__toggle.ui.button {
    position: absolute;
    top: 20px;
    right: 30px;
    padding: 4px 0px 4px 8px;
    outline: none;
    background: transparent;
    border: none;
    color: $gray--darkest;
  }

  &__container {
    min-height: 100vh;

    .ui.secondary.pointing.menu {
      border-color: $gray--dark;
      border-bottom-width: 1px;

      .item {
        .item-style {
          color: $gray--darkest;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.91px;
          line-height: 26px;
          font-family: $sans-reg;
        }

        .disable {
          color: $gray--dark;
        }

        .label__count {
          min-width: 32px;
          background-color: $black;
          color: $white;
          font-family: $sans-reg;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          border-radius: 7px;
        }

        &.active {
          @extend %item-active;

          &:hover {
            @extend %item-active;
          }
        }
      }
    }
  }
}

.ui.attached.bottom.segment {
  border: 0px;
}

%item-active {
  border-bottom: 5px solid $bcg-green--blue-green;

  .label__count {
    background-color: $bcg-green--blue-green;
  }
}