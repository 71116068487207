@import "src/global-styles/colors";
@import "src/global-styles/typography";

// for the font style
@mixin textStyle($family, $size, $color, $weight: normal, $lineHeight: 1.71) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-stretch: normal;
  font-style: normal;
  line-height: $lineHeight;
  letter-spacing: normal;
  color: $color;
}

@mixin getLayoutInnerPadding {
  padding-left: 134px;
  padding-right: 134px;
  max-width: 1567px;
}


@mixin multilineellipsis($lines, $lineHeight: 20px) {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: $lineHeight;
  max-height: #{$lines * $lineHeight};
  /* Fallback for non-webkit */
  line-clamp: $lines;
  -webkit-line-clamp: $lines;
  -moz-line-clamp: $lines;
}

@mixin alignCenterViaAbsolute() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin disabledLink() {
  cursor: default;
  pointer-events: none;
}

@mixin beyondLayout($width) {
  margin-left: -$width;
  margin-right: -$width;
  padding-left: $width;
  padding-right: $width;
}

@mixin responseLoaderInfoText() {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: $gray--500;
  padding-left: 5px;
}