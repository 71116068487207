@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.case {
  &__block {
    padding-top: 30px;

    .case__inputwithicon .case__input--block input.case__input {
      border: none !important;
      padding: 8px !important;
      margin-bottom: 8px;
      width: 140px;

      &:focus {
        outline: none;
      }
    }

    label {
      font-family: $sans-reg;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $gray--darkest;
    }

    .label__disabled {
      opacity: 0.5;
    }
  }

  &__link,
  &__link:hover {
    color: $gray--darkest;
  }

  &__link:hover {
    text-decoration: underline;
  }

  &__inputwithicon {
    display: flex;
    align-items: flex-start;
    border: 1px solid $gray--dark;
    border-radius: 4px;
    background-color: $white;
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;

    .case__input--block {
      background-color: $gray--light;
    }

    .ui.form .fields & input {
      background-color: $gray--light;
    }
  }

  &__input--block {
    width: 100%;
    flex: 1;
    padding: 11px 9px 1px;
  }

  &__btn--black {
    width: 44px;
    height: 44px;
    background: $gray--darkest;
    border-radius: 5px;
    margin: 6px 5px 5px 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__plus-icon {
    width: 18px;
    height: 18px;
  }

  &__selecteditem {
    font-size: 14px;
    padding: 4px 11px 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    background-color: $gray--light;
    margin: 0 8px 8px 0;

    span {
      padding: 0 10px 0 0;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      color: $gray--darkest;
      font-family: $sans-reg;
      line-height: 25px;
    }

    .ui.image {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      border-radius: 17px;
      object-fit: cover;
      height: 20px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  &__error {
    display: flex;

    >img {
      margin-right: 10px;
    }

    >span {
      font-size: 12px;
    }
  }
}