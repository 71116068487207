@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.sidebar {
    height: 100vh;
    z-index: 1;
    display: flex;
    position: fixed;

    &__wrap {
        background: #323232;
        width: 50px;
        height: 100%;
        position: relative;
        padding-top: 10px;
        padding: 10px 5px;

    }

    &__image {
        background-color: $purple--300;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 3px;

        &:hover {
            cursor: pointer
        }
    }
}