// Import
@import "src/global-styles/colors";

.mail-button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  &__text{
    margin: 0;
    padding: 0 10px 0 0px;
    color: $white;
    font-family: "Henderson BCG Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }
  &__btn.ui.button{
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: #3FAD93;
    color: #FFFFFF;
    font-family: "Henderson BCG Sans", sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 14px;
    text-align: right;    
  }
  &__img.ui.image{
    display: inline;
    margin-left: 5px;
    margin-top: -2px;
  }
}