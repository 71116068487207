@import "src/global-styles/colors";

.example {
    word-break: break-word;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: 2px solid rgba(115, 118, 225, 1);
    }

    .exampleText {
        margin: 0;
        font-size: 22px;
        width: 280px;
        height: 100px;

        background: linear-gradient(92.09deg, #3823EE 16.28%, #E464A6 55.47%, #ED9F60 101.65%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        &::selection {
            -webkit-text-fill-color: $black;
        }
    }
}

.examplesNavList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1;
    justify-content: center;
    padding-top: 35px;
}

@media only screen and (max-height: 780px) {
    .exampleText {
        font-size: 20px;
        height: 80px;
    }
}