@import "src/global-styles/colors";
@import "src/global-styles/typography";

@mixin cssHollowCheckbox() {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: $white;
  border-color: $checkboxgray;
  margin-top: 5px;
}

@mixin cssTickMark($fillColor: $gray-filler) {
  content: "";
  position: absolute;
  left: 5px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid $white;
  background-color: $fillColor;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  border-radius: 0;

}

// for the font style
@mixin textStyle($family, $size, $color, $weight: normal, $lineHeight: 1.71) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-stretch: normal;
  font-style: normal;
  line-height: $lineHeight;
  letter-spacing: normal;
  color: $color;
}