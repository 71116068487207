@import "src/global-styles/colors";

.userChatMessage {

    &__container{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        max-width: 80%;
        margin-left: auto;
        align-items: flex-end;
        gap: 10px;
    }

    &__profileicon{
        border-radius: 50%;
        overflow: hidden;
        font-size: 14px;
        width: 28px;
        height: 28px;
        background: $purple--300;
        color: $white;
    }

    &__message {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        padding: 20px;
        background: $gray--light;
        color: $gray--700;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: transparent solid 1px;
    }
}
