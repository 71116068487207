@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";



.chathistory {
    height: 100vh;
    z-index: 1;
    display: flex;
    position: fixed;
    margin-left: 50px;

    &-disabled {
        pointer-events: none;

        .ui.dimmer {
            background-color: $gray--light;
            opacity: 0.5;
        }
    }

    &__wrap {
        width: 260px;
        background: $gray--light;
        // height: 100%;
        padding: 20px 0px;
        // padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray--300;
        padding: 0px 10px;

        h4 {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 20px;
            color: $gray--700;
        }
    }

    &__arrowStyle {
        transform: translateX(20px);
    }
}

.newchatbutton {
    padding-left: 10px;
}