@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.thoughtchain {
  &__wrap {}

  &__heading {
    height: 40px;
    background: #F2F2F2;
    border-right: 1px solid #D4D4D4;
    padding: 8px 13px;
    position: fixed;
    width: calc(60% - 18px);
    z-index: 3;

    img.ui.image {
      display: inline-block;
      vertical-align: top;
    }

    h3 {
      font-size: 14px;
      line-height: 21px;
      font-family: $sans-bold;
      display: inline-block;
      margin: 0;
      margin-left: 6px;
      color: #323232;
    }

    &-left,
    &-right {
      display: inline-block;
      vertical-align: top;
    }

    &-right {
      float: right;
    }
  }

  &__iconWrap {
    height: 23px;
    width: 23px;
    display: inline-block;
    vertical-align: top;
    background: #535353;

    text-align: center;
    padding-top: 5px;
  }

  &__pill {
    display: inline-block;
    vertical-align: top;
    margin-left: 25px;
    cursor: pointer;

    h5 {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      margin-left: 6px;
      font-size: 12px;
      font-family: $sans-light;
      text-transform: uppercase;
      color: $gray--500;
      line-height: 23px;
    }
  }

  &__cross {
    margin-top: 4px;
    cursor: pointer;
  }

  &__body {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__searched {
      p {
        padding: 2px 10px;
      }
    }
  }
}