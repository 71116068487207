@import "src/global-styles/colors";
@import "src/mixins/_mixins";

.loading-icon {
    &__wrap {
        display: flex;
        align-items: center;
    }

    &__item {
        width: fit-content;
        border-radius: 3px;
    }

    &__info {
        @include responseLoaderInfoText();
    }
}