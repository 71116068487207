@import "src/global-styles/colors";
@import "src/global-styles/typography";

.pill {
  background-color: $gray--light;
  border-radius: 20px;
  padding: 0 40px;
  display: inline-block;
  text-align: center;
  max-width: 100%;

  span {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    font-family: $sans-reg;
    color: $black;
  }

  &.active {
    background-color: $black;

    span {
      color: $white;
    }
  }

  .ui.image {
    display: inline;
    margin-left: 15px;

    &.pill__saveprogressicon {
      animation: spin 1s linear infinite;
    }
  }

  &-disable {
    .ui.image {
      display: inline;
      margin: 0 5px 0 0px;
    }
  }

  &-polyH {
    .ui.image {
      display: inline;
      margin: 0 5px 0 0px;
    }
  }

  &__arrow-right {
    margin: 0 10px 0 5px;
    position: relative;

    &::before {
      content: "";
      width: 10px;
      height: 5px;
      position: absolute;
      top: 2px;
      left: 0px;
      border-radius: 2px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid $gray--dark;
    }

    &:first-child {
      margin-left: 0;
      display: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tooltipwrap.pillTooltipClass {
  vertical-align: top;
  margin-left: 0;

  .tooltipwrap__popup {
    max-width: 700px;
  }
}