@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.citation-section {
    &__wrap {
        width: 100%;
    }

    &__heading {
        background: $gray--light;
        border-right: 1px solid $gray--300;
        padding: 8px 13px;
        position: fixed;
        width: calc(60% - 18px);
        z-index: 3;

        h3 {
            font-size: 14px;
            line-height: 21px;
            font-family: $sans-bold;
            display: inline-block;
            margin: 0;
            margin-left: 6px;
            color: $bcg-dark-red;
        }

        &-left,
        &-right {
            display: inline-block;
            vertical-align: top;
        }

        &-right {
            float: right;
        }

    }

    &__iconWrap {
        height: 23px;
        width: 23px;
        display: inline-block;
        vertical-align: top;
        background: $gray--500;

        text-align: center;
        padding-top: 5px;
        padding-left: 5px;
    }

    &__pill {
        display: inline-block;
        vertical-align: top;
        margin-left: 25px;
        cursor: pointer;

        &-disable {

            .citation-section__iconWrap,
            h5 {
                opacity: 0.5;
            }

        }

        h5,
        &--text {
            display: inline-block;
            vertical-align: top;
            margin: 0;
            margin-left: 6px;
            font-size: 12px;
            font-family: $sans-light;
            text-transform: uppercase;
            color: $gray--500;
            line-height: 23px;
            font-weight: 700;
        }
    }

    &__cross {
        margin-top: 4px;
        cursor: pointer;
    }

    &__body {
        padding-top: 45px;
        padding-left: 10px;
    }

    &__filename {
        background: linear-gradient(86deg, rgb(86, 201, 137), rgb(63, 160, 173));
        color: $white;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        &-image {
            width: 16px;
        }
    }

    &__unsupportedText {
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        color: $gray--dark;
        background-color: $black
    }
}