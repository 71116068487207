@import "src/global-styles/colors";
@import "src/global-styles/typography";

.custodian {
  margin-top: 10px;

  .pill {
    margin-right: 10px;
    padding: 5px 20px;
    cursor: pointer;
    margin-bottom: 10px;

    span {
      font-weight: normal;
    }

    &:last-child {
      margin-right: 0;
    }

    &-disable {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}