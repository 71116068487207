.polyHierarchi {
    cursor: default;

    &__heightWidthSet {
        width: 20px;
        height: 20px;
    }
}

.imageIcon {
    margin: 0 7px;
}