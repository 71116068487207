@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.profileicon {
    &__wrap {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
    }
}