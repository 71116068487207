@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

body,
html {
  height: auto;
}

.layout {
  // min-width: 1240px;
  min-width: 1170px;
  margin-top: 50px;

  .displaynone {
    display: none !important;
  }
}

.two-lines-ellipsis {
  @include multilineellipsis(2);
}

.three-lines-ellipsis {
  @include multilineellipsis(3);
}