@import "src/global-styles/colors";
@import "src/global-styles/typography";

.ReactModal {
  &__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1007;

    &.drawfromright {
      opacity: 0;
      transform: translateX(100px);
      transition: all 200ms ease-in-out;
      &--after-open {
        opacity: 1;
        transform: translateX(0px);
      }
      &--before-close {
        opacity: 0;
        transform: translateX(100px);
      }
      
    }
  }
  &__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: $white;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    width: 35%;
  }
  &__Body--open {
    overflow-y: hidden;
  }
  
}

.ReactModal {  
    &__Content.drawfromright {
      height: 100%;
      width: 90%;
      left: 55%;
      padding: 0;
      font-family: $sans-reg;
      border: none;
      border-radius: 0;
      box-shadow: 0 6px 30px 0 rgba(0,0,0,0.32);
    }
}
