@import 'src/global-styles/colors';
@import 'src/global-styles/typography';


.tooltipwrap {
  &__popup.content {
    background: $gray--darkest;
    z-index: 9999;
    font-family: $sans-reg;
    font-size: 12px;
    line-height: 21px;
    border-radius: 4px;
    border-color: $gray--darkest;
    opacity: 0.95;
    color: $white;
    margin-right: -1.3em;
    box-shadow: 1px 0px 5px $gray--dark;
    max-width: 400px;
    pointer-events: all;
    font-weight: 400;

    p {
      font-family: $sans-reg;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }

    a {
      color: white;
      text-decoration: underline;
    }

    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }

    &.place-left {
      margin-left: -4px;
    }

    &.place-bottom {
      &:after {
        border-bottom-color: $gray--darkest;
      }
    }

    &:after {
      -webkit-filter: drop-shadow(1px 0px 5px $gray--darkest);
      filter: drop-shadow(1px 0px 5px $gray--darkest);
    }

    &.inverted {
      background: $white;
      color: $gray--darkest;
      border-color: $white;
      box-shadow: 1px 0px 5px $gray--dark;

      &:after {
        border-top-color: $white;
        -webkit-filter: drop-shadow(1px 0px 5px $gray--dark);
        filter: drop-shadow(1px 0px 5px $gray--dark);
      }
    }
  }

  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;

  .ui.form .fields.grouped .field label & img.ui.image {
    margin-left: 0;
  }

  .tooltip__label+& {
    margin-left: 5px;
  }

  &.customTooltipClass {
    margin-left: 1px;
  }

  &__scrollbar {
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background: $gray !important;
    }
  }
}