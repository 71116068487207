@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import "src/mixins/_mixins";

.ui.checkbox {
  min-height: 14px;
  min-width: 14px;
  line-height: 14px;
  top: 20%;
}

.ui.checkbox .box::before,
.ui.checkbox label::before,
.ui.checkbox .box::after,
.ui.checkbox label::after {
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.ui.checkbox .box::after,
.ui.checkbox label::after {
  color: $white !important;
}


.custom__checkbox {
  &.ui.checkbox {
    display: block;

    input {
      &~label:before {
        @include cssHollowCheckbox();
      }

      &:checked {
        &~label:before {
          background-color: $bcg-green--blue-green;
          border-color: $bcg-green--blue-green;
        }

        &~label:after {
          @include cssTickMark($bcg-green--blue-green);
        }
      }
    }

    label {
      @include textStyle($sans-bold, 14px, $gray--darkest, normal, 24px);
      padding-left: 25px;
      text-align: left;

      &:before {
        top: 5px;
      }
    }
  }
}