$bcg-green--light-blue-green: #3fad93;

// Grayscale
$gray: gray;
$black: #000000;
$blacktwo: #000000;
$gray--darkest: #333333;
$gray--700: #323232;
$gray--400: #B1B1B1;
$gray--500: #535353;
$gray--300: #D4D4D4;
$gray--darker: #707070;
$gray--dark: #b7b7b7;
$gray--light: #f2f2f2;
$gray--lightest: #fafafa;
$red--v1: #D82216;
$gray-D9D9D9: #D9D9D9;
$purple--300: #A97CDD;
$purple--dark: #894BD1;
$purple--v1: #5D289B;
$white: #ffffff;
$white--v1: #e8ebfa;
$white--v2: #d1dbfa;
$blue: #123bb6;
$blue-primary: rgb(0, 120, 212);
$checkboxgray: #979797;
$green--500: #197A56;
$bcg-green: #177b57;
$bcg-green--blue-green: #3ead93;
$gray--light--disable: #f2f2f2cc;
$gray--whitish: #dddbda;
$autoTaggerColor: #E0F1FF;
$notify-warn-highlight: #ff9800;
$bcg-dark-red: #323232;
$transparent: rgba(0, 0, 0, 0); // transparent convers to initial, rgba(0,0,0,0) translates to transparent.......... compilers these days