@import "src/global-styles/colors";
@import "src/global-styles/typography";

.answerContainer {

    &__inner {
        padding: 20px;
        padding-bottom: 10px;
        background: rgba(233, 221, 246, 0.3);
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: transparent solid 1px;
        color: $gray--700;
        display: flex;
        flex-direction: column;

        &--active {
            border: 1px solid #894BD1;
        }
    }

    &__counter {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 5px;
    }

    &__countertext {
        font-size: 14px;
        line-height: 20px;
        font-family: $sans-bold;

        &::after {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            content: "";
            margin-left: 10px;
            background: $bcg-green;
            display: inline-block;
            vertical-align: middle;
        }

        &-orange {
            &::after {
                background: orange;
            }
        }

        &-red {
            &::after {
                background: $red--v1;
            }
        }
    }

    &__icons {
        display: flex;
        padding: 10px 0;
        justify-content: space-between;
    }

    &__text {
        padding-right: 33px;
    }

    &__thoughtchain,
    &__clipboard {
        &.ui.image {
            width: 32px;
            height: 20px;
            display: inline-block;
            margin-left: 10px;
            border-left: 1px solid #D4D4D4;
            padding-left: 12px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
}

.answerLogo {
    font-size: 28px;
}

.answerText {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-top: 0;

    table {
        border-collapse: collapse;
    }

    td,
    th {
        border: 1px solid;
        padding: 5px;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;

        li {
            list-style-type: disc;
        }
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;

        li {
            list-style-type: decimal;
        }
    }

    ul ul,
    ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }

    ol ol,
    ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }
}

.citation {
    display: flex;
    text-align: center;
    border-radius: 14px;
    line-height: 22px;
    border: 1px solid $gray--400;
    height: fit-content;
    flex-direction: column;
    padding: 15px 8px;
    margin-top: 10px;
    float: left;
    margin-right: 10px;
    width: calc(33% - 9px);

    // width: min-content;
    &__wrap {
        padding-top: 16px;
    }

    &__heading {
        margin-right: 5px;
        font-weight: 600;
        line-height: 24px;
        font-size: 12px;
        margin: 0;
    }

    &__withImage {
        min-height: 235px;
        cursor: pointer;
    }

    &__withImage-2 {
        width: calc(66% - 9px);

        .citation__imageswrap {
            width: 50%;
        }
    }

    &__withImage-3 {
        width: calc(99% - 9px);

        .citation__imageswrap {
            width: 33.33%;
        }
    }

    &__details {
        flex-direction: row;
        display: flex;
        justify-content: center;
        place-content: flex-start;
        flex-flow: wrap;
        margin-bottom: 15px;
    }

    &__slide-number {
        display: inline-block;
        height: 22px;
        width: 22px;
        color: $purple--v1;
        font-weight: 700;

    }

    &__title {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        padding-left: 5px;
        color: $gray--700;
        min-width: 200px;
        display: inline-block;
        min-width: auto;

    }

    &__imageswrap {
        width: 100%;

        span {
            display: block;
        }

        img {
            width: 100%;
            max-height: 117px;
        }
    }

    &__unsupportedText {
        display: block;
        text-align: center;
        margin: 0px auto;
        font-size: 10px;
        font-weight: bold;
        color: $gray--dark;
        background-color: $black;
    }
}

.citation:hover {
    text-decoration: underline;
}

.followupQuestionsList {
    margin-top: 10px;
}

.followupQuestionLearnMore {
    margin-right: 5px;
    font-weight: 600;
    line-height: 24px;
}

.followupQuestion {
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    padding: 0px 8px;
    background: #e8ebfa;
    color: black;
    font-style: italic;
    text-decoration: none;
    cursor: pointer;
}

.supContainer {
    text-decoration: none;
    cursor: pointer;
}

.supContainer:hover {
    text-decoration: underline;
}

sup {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    vertical-align: top;
    top: -1;
    margin: 0px 2px;
    min-width: 14px;
    height: 14px;
    border-radius: 3px;
    background: #d1dbfa;
    color: #123bb6;
    text-decoration-color: transparent;
    outline: transparent solid 1px;
    cursor: pointer;
}

.retryButton {
    width: 80px;
    height: 20px;
    border: 1px solid $blue-primary;
    font-size: 14px;
    font-family: $sans-reg;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 16px;
    text-decoration: none;
    text-align: center;
    min-width: 80px;
    height: 32px;
    background-color: $blue-primary;
    color: $white;
}

@keyframes loading {
    0% {
        content: "";
    }

    25% {
        content: ".";
    }

    50% {
        content: "..";
    }

    75% {
        content: "...";
    }

    100% {
        content: "";
    }
}

.loadingdots::after {
    content: "";
    animation: loading 1s infinite;
}