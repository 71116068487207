@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.mainheader {
  &__wrap {
    background: $gray--light;
    padding: 6px 0 7px 0;
    background: $white;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
  }

  &__logo {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;

    img.ui.image {
      height: 30px;
      display: inline-block;
      vertical-align: text-top;
      margin-right: 10px;
    }

    &--text {
      display: inline-block;
      vertical-align: top;

      h1 {
        font-size: 10px;
        line-height: 13px;
        // font-family: 'Source Sans Pro';
        color: $purple--v1;
        margin: 0;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #323232;
        // font-family: 'Source Sans Pro';
        display: block;
        margin: 0;
      }
    }
  }

  &__userProfile {
    float: right;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 15px;
    width: 32px;
    height: 32px;
    background: $purple--300;
    color: $white;
  }
}