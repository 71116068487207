@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.container {
    &__chat {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    &__overlaylock {
        position: fixed;
        background: rgba(255, 255, 255, 0.72);
        height: 100vh;
        // width: 100%;
        top: 0;
        // padding-left: 310px;
        width: calc(100% - 310px);
        padding-top: 300px;

        p {
            font-size: 22px;
            text-align: center;
            margin-bottom: 0;
            color: $red--v1;
            background: #fff;

            img.ui.image {
                display: inline;
            }
        }
    }
}

.chatRoot {
    flex: 1;
    display: flex;
}

.chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 1024px;
    padding-top: 60px;

    .ui.image {
        margin: -115px 0 -30px;
        width: 244px;
        height: 244px;
        padding: 50px;
    }
}

.chatEmptyStateTitle {
    margin: 0;
    font-weight: 700;
    font-size: 47px;
    line-height: 53px;
    text-align: center;
    background: linear-gradient(92.09deg, #3823EE 16.28%, #E464A6 55.47%, #ED9F60 101.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    width: 568px;
    // text-fill-color: transparent;
}

.chatEmptyStateSubtitle {
    margin: 0;
    font-weight: 700;
    font-size: 47px;
    line-height: 53px;
    text-align: center;

    background: linear-gradient(92.09deg, #3823EE 16.28%, #E464A6 55.47%, #ED9F60 101.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
}

.chatEmptyStateTitle::selection,
.chatEmptyStateSubtitle::selection {
    -webkit-text-fill-color: black;
}

.chatMessageStream {
    flex-grow: 1;
    max-width: 1028px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
}

.unabletoload {
    position: absolute;
    background: red;
    padding: 10px 20px;
    border-radius: 10px;
    color: $white;
}

.chatMessageGpt {
    margin-bottom: 20px;
    max-width: 80%;
    display: flex;
    min-width: 500px;
}

.chatMessageGptMinWidth {
    max-width: 500px;
    margin-bottom: 20px;

    img.ui.image {
        width: 30px;
        height: 28px;
    }
}


.chatInput {
    background: linear-gradient(90deg, #3823EE -0.95%, rgba(228, 100, 166, 0.504708) 49.05%, #ED9F60 100%);
    border-radius: 6px;
    margin-bottom: 8px;
    padding-bottom: 1px;


    &__wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
    }

    &__inner {
        width: calc(100% - (310px + 40px + 37px));
        margin-left: calc(310px + 40px);

        &--disabled {
            opacity: 0.5;
        }
    }

    &__heading {
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        color: $gray--700;
        font-family: $sans-light;
        padding: 7px;

        img.ui.image {
            display: inline-block;
            margin-right: 5px;
        }
    }

    &__requiredField {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 4px;
    }

    &__requiredField::before {
        content: '*';
        color: $red--v1;
        position: relative;
    }
}

.main {
    /* IE11+ don't support <main> */
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $gray--light;
    display: flex;
}

.right-sec {
    background: $white;
    padding-left: 310px;
    width: 100%;
    padding-bottom: 150px;
    border-right: 1px solid $gray--300;
    position: relative;
}

.detail-sec {
    background-color: $white;
    width: 60%;
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 50px);
}

.opened-detailsec {
    .right-sec {
        width: 40%;
        overflow-y: auto;
        padding-left: 50px;
        height: calc(100vh - 50px);
    }

    .detail-sec {
        width: 60%;
        display: block;
        border-right: 1px solid $gray--300;
    }

    .chatInput__wrap {
        width: calc(40% - 10px);

        .chatInput__inner {
            width: calc(100% - 100px);
            margin-left: 85px;
        }
    }

    .chatEmptyStateTitle {
        width: auto;
    }

    .citation__withImage {
        width: calc(50% - 12px);

        .citation__imageswrap {
            width: 100%;
        }
    }
}

.maxchatlimit {
    position: relative;
    text-align: center;

    &__inner {
        background: #fff;
        position: relative;
        padding: 0 15px;
    }

    &::before {
        position: absolute;
        content: "";
        border-top: 1px solid #ccc;
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0;
    }

    img.ui.image {
        display: inline-block;
        margin-right: 5px;
    }
}