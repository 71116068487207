@import "src/global-styles/colors";
@import "src/global-styles/typography";

.ui.button {
  font-family: $sans-bold;
  text-transform: uppercase;
  background-color: $blacktwo;
  margin-right: 0;
  border-radius: 0;
  color: $white;
  position: relative;
  line-height: 1.11;
  font-size: 18px;

  .icon {
    height: auto;
  }

  &.invert {
    border: solid 1px $gray--darker;
    background: $gray--light;
    color: $gray--darker;
  }

  &.action {
    margin: 0 0 0 8px;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0;
    background: $transparent;
    outline: 0;
    padding: 0;
  }

  &.black {
    border: 2px solid $gray--darkest;
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.8px;
    color: $gray--darkest;
    background: transparent;
  }

  &.green.green {
    height: 35px;
    border-radius: 5px;
    background-color: $bcg-green--light-blue-green;
    font-size: 12px;
    letter-spacing: 0.8px;

    &.button:hover,
    &.button:active,
    &.button:focus,
    &.buttons .button:hover,
    &.buttons .button:active,
    &.buttons .button:focus {
      background-color: $bcg-green--light-blue-green;
    }
  }

  &.white.white {
    border: 2px solid $white;
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.8px;
    color: $white;
    background: transparent;
  }

  &.newchatbutton.green--500 {
    border: 1px solid $green--500;
    height: 40px;
    width: 236px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $green--500;
    background: transparent;
    margin-bottom: 40px;
    margin-left: 10px;
  }

  &.red {
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: 0.8px;
  }

  &.button_pa {
    background-color: #3fad93;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    line-height: 21px;
  }

  &.button_pa:hover {
    color: $gray--light;
  }

}