@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.textareacounter{
    position: relative;
    border: 1px solid $gray--dark;
    border-radius: 4px;
    &__textarea{
        resize: none !important;
        height: 117px;
    }
    .ui.form &__textarea {
        color: $gray--darkest;
        font-size: 14px;
        border: 0;
        padding-bottom: 1px;
    }
    
    span.counter {                
        text-align: right;
        display: block;
        padding-right: 15px;
        font-size: 14px;
        color: $gray--dark;
        padding-bottom: 0px;
        line-height: 14px;
        padding-bottom: 4px;
    }
}
