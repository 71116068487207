@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.chatsessionhistory {

    &__list {
        flex: 2;
        padding-top: 5px;
        // max-height: calc(100% - 40px);
        overflow-y: auto;
        margin-bottom: 20px;
    }

    &__wrap {
        min-height: 40px;
        display: flex;
        align-items: center;
        // justify-content: space-around;
        padding-right: 8px;

        &--active {
            background-color: $white;
            border-right: 4px solid $purple--300;
            padding-right: 4px;

            &>img {
                translate: 3px;
            }
        }
    }

    &__icons {
        margin-left: auto;

        .fa-check,
        .fa-times {
            img.ui.image {
                display: inline;
            }

            margin-right: 10px;
            cursor: pointer;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding-left: 10px;
        width: 193px;
        cursor: pointer;
        // &:hover {
        //     border-bottom: 0.5px solid $gray--300;
        // }
    }

    &__title {
        padding-left: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $black;
    }

    &__delete {
        margin-right: 7px;
        cursor: pointer;
    }
}